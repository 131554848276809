import React, {createContext, useState, useEffect} from 'react';
import App from 'next/app';
import {useRouter} from 'next/router';
import {debounce} from 'aac-components/utils/throttle';
import AdminBar from 'aac-components/components/AdminBar';
import GeoTargetTab from 'aac-components/components/GeoTargetTab/index';
import {getMatchFacility} from 'aac-components/components/GeoTargetDrawerContent/helpers';
import {getIpAddress} from 'aac-components/utils/getIpAddress';
import fetch, {WPFetch} from 'aac-components/utils/fetch';
import menuItemCreator from '../lib/actions/menuItemCreator';
import {
    getCookie,
    getSegmentFromProps,
    getSwapNumber,
    loadStateFromLocalStorage,
    saveStateToLocalStorage,
    setContentSegment,
    getSessionStorageState,
} from '../lib/utils';

const initialAppState = {
    isMobile: true,
    contentSegment: '',
    callTrackingNumber: {
        href: '',
        display: '',
    },
    history: [],
    sessionStorageState: {
        geolocation: {},
    },
};

export const AppContext = createContext(initialAppState);

export default function MyApp(props) {
    const {Component, pageProps, blockingData, siteOptions, ipAddress} = props;
    const [state, setState] = useState(initialAppState);
    const [mounted, setMounted] = useState(false);

    const updateState = (newState) => {
        setState((prevState) => ({
            ...prevState,
            ...newState,
        }));
    };
    const {asPath} = useRouter();

    /**
     * Gets value from <body>
     * @param {string} attribute - the attribute to get from the <body>
     * @returns {string} the value of the attribute
     */
    const getBodyAttribute = (attribute = '') => {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;
        const theBody = document.querySelector('body');
        return theBody.getAttribute(attribute);
    };

    /**
     * Sets value to an attribute on the <body>
     * @param {string} attribute - the attribute
     * @param {string} value - the value of the attribute
     */
    const setBodyAttribute = (attribute = '', value = '') => {
        if (typeof window === 'undefined' || typeof document === 'undefined') return;
        const theBody = document.querySelector('body');
        theBody.setAttribute(attribute, value);
    };

    const reportWindowSize = () => {
        const isMobile = window && window.innerWidth <= 992;
        // only update state if the state has change.
        if (state.isMobile !== isMobile) {
            updateState({isMobile});
        }
    };

    /**
     * Merge history array into state from localStorage on any serverside navigation
     * Not necessary on clientside navigations, although you could
     */
    useEffect(() => {
        setMounted(true);
        const localStorageState = loadStateFromLocalStorage(state);
        updateState(localStorageState);
    }, []);

    /**
     * Save history to localStorage
     */
    useEffect(() => {
        // save new state to localStorage so we can track user history (we only save state.history & maybe state.dynamicKeywords)
        saveStateToLocalStorage(state);
    }, [state?.history]);

    /**
     * Swap numbers for call tracking whenever they change
     */
    useEffect(() => {
        window?.CallTrk && window?.CallTrk?.swap();
    }, [state?.callTrackingNumber?.href]);

    /**
     * Updates needed for when user changes pages (serverside or clientside navigation)
     */
    useEffect(() => {
        // scroll to the top after clientside navigation
        document.body.scrollTop = 0;

        // lazyload images not using next/image
        if (window?.lazyLoadInstance) lazyLoadInstance?.update();

        const sessionStorageState = getSessionStorageState(state.sessionStorageState);

        // record window size
        window &&
            (reportWindowSize(),
            window.addEventListener('resize', debounce(reportWindowSize, 500)));

        const contentSegment = getSegmentFromProps(pageProps);
        const callTrackingNumber = getSwapNumber(contentSegment);

        contentSegment && window.ga && window.ga('set', 'contentGroup1', contentSegment);

        // Set the callTrackingNumber to the body tag
        setBodyAttribute('data-callrail', callTrackingNumber.display);

        // update state.history with new slug user visits
        setState((prevState) => {
            const newState = {
                ...prevState,
                contentSegment,
                callTrackingNumber,
                history: [prevState.history.slice(-1)[0], asPath],
            };

            if (!mounted) {
                newState.sessionStorageState = sessionStorageState;
            }

            return newState;
        });
    }, [asPath]);

    /**
     * Update callTrackingNumber whenever LiftAIScoreChanged event & contentSegment determines we have a mid-intent user
     */
    useEffect(() => {
        const handleLiftAIScoreChange = (event) => {
            // note: can't rely on state in here, grab from DOM or mirror needed state with useRef
            const callTrackingNumber = getSwapNumber(
                getBodyAttribute('data-segment'),
                event,
            );
            if (callTrackingNumber.display !== getBodyAttribute('data-callrail')) {
                updateState({
                    callTrackingNumber,
                });
                setBodyAttribute('data-callrail', callTrackingNumber.display);
            }
        };

        document.addEventListener(
            'LiftAIScoreChanged',
            debounce(handleLiftAIScoreChange, 500),
        );
        return () =>
            document.removeEventListener(
                'LiftAIScoreChanged',
                debounce(handleLiftAIScoreChange, 500),
            );
    }, []);

    /**
     * Helper function to grab user's geolocation
     */
    const getUserGeolocation = async (ipAddress) => {
        // ::1 is the value for local env
        if (!ipAddress || ipAddress === '::1') return;

        try {
            const response = await fetch(`/cp-aac/api/geolocation/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify({ipAddress}),
            });
            const geolocation = await response.json();
            let geolocationWithMatch = {};
            if (geolocation?.data) {
                // Check if user is within distance of a facility
                const matchFacility = getMatchFacility(geolocation.data);
                geolocationWithMatch = {
                    ...geolocation.data,
                    facility: matchFacility.siteKey,
                    distance: matchFacility.distance,
                };
            }

            saveGeolocationToCookie({
                facility: geolocationWithMatch.facility,
                distance: geolocationWithMatch.distance,
            });

            setSessionStorageKey(
                'geolocation',
                geolocation?.data ? geolocationWithMatch : geolocation,
            );
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Helper function to update sessionStorage
     */
    const setSessionStorageKey = (keyId, value) => {
        const sessionStorageState = {
            ...state.sessionStorageState,
            [keyId]: value,
        };
        window &&
            window.sessionStorage &&
            window.sessionStorage.setItem('AacApp', JSON.stringify(sessionStorageState));
        updateState({sessionStorageState});
    };

    const saveGeolocationToCookie = (data) => {
        // max-age = 1 year (365*60*60*24)
        document.cookie = `aacAppState=${encodeURIComponent(
            JSON.stringify(data),
        )}; max-age=31536000; path=/`;
    };

    /**
     * Get user's geolocation based off their IP
     */
    // useEffect(() => {
    //     // Fetch user's geolocation data only if it wasn't already fetched
    //     // We have to grab data directly from window.sessionStorage here
    //     const sessionStorageState = getSessionStorageState();
    //     if (Object.keys(sessionStorageState?.geolocation || {}).length === 0) {
    //         getUserGeolocation(ipAddress);
    //     }
    // }, [ipAddress]);

    const contentSegment = getSegmentFromProps(pageProps);
    const showGeoTargetTab =
        state.contentSegment && state.contentSegment !== 'conversion-pages';

    return (
        <AppContext.Provider
            value={{
                ...state,
                ...blockingData,
            }}>
            <div
                id="page-segment"
                className={contentSegment}
                data-segment={contentSegment}
            />
            {blockingData?.isLoggedIn && <AdminBar pageProps={pageProps} />}
            <Component {...pageProps} />
            {showGeoTargetTab && (
                <GeoTargetTab
                    geolocation={state?.sessionStorageState?.geolocation}
                    callTrackingNumber={state.callTrackingNumber}
                />
            )}
        </AppContext.Provider>
    );
}

/**
 * Execute any code that should run before the Application is rendered
 * @param {Object} appContext
 */
MyApp.getInitialProps = async (appContext) => {
    // get your appProps, needed per next.js docs https://nextjs.org/docs/advanced-features/custom-app
    const appProps = await App.getInitialProps(appContext);

    // See if the user is logged into the wordpress admin dashboard
    const {ctx = {}} = appContext;
    const settings =
        (ctx?.req?.headers?.cookie &&
            ctx.req.headers.cookie
                .split('; ')
                .find((row) => row.startsWith('wordpress_logged_in_'))) ||
        false;
    const isLoggedIn = settings !== false;
    const {clearCache = false} = ctx?.req?.query || {};

    // Get user's IP
    const ipAddress =
        process.env.APP_ENV === 'production'
            ? getIpAddress(ctx?.req)
            : ctx?.req?.query?.ipAddress;

    // Get our blocking data. Menus and global ACF site options
    // https://medium.com/@az/i18n-next-js-app-with-server-side-rendering-and-user-language-aware-caching-part-1-ae1fce25a693
    // put the menu data into in-memory app cache so we don't call on every serverside request
    // this cache gets cleared when the app is restarted ex. deployments
    let menuItems = [];
    const {data: menuData} = await WPFetch(
        '/wordpress/aac/v1/menu/primary-menu/',
        clearCache,
    );
    const menu_data = menuData || null;
    if (menu_data && !menu_data.error && Array.isArray(menu_data))
        menuItems = menu_data?.map((item) => menuItemCreator(item));

    let siteOptions = {};
    const {data: siteOptionsData} = await WPFetch(
        '/wordpress/acf/v3/options/options/',
        clearCache,
    );
    const option_data = siteOptionsData || null;
    if (option_data && !option_data.error) siteOptions = option_data?.acf ?? {};

    return {
        ...appProps,
        ipAddress,
        blockingData: {
            menuItems,
            siteOptions,
            isLoggedIn,
        },
    };
};
