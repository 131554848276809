import url from 'url';
import parse from 'html-react-parser';
import pageCreator from './actions/postCreator';

import {complianceUrls, callTrackingNumber, callTrackingNumberLink} from './globals';

export const getProp = (path = [], data, defaultValue = null) =>
    path.reduce((xs, x) => (xs && (xs[x] || xs[x] === 0) ? xs[x] : defaultValue), data);

export const getProps = (obj, keys, defaultValue = null) => {
    const objectKeys = keys.split('.');
    const allKeys = objectKeys.reduce((acc, key) => {
        const match = key.match(/\[\d\]$/);
        if (match) {
            const propName = key.slice(0, match.index);
            propName && acc.push(propName);
            const index = +key.slice(match.index + 1, key.length - 1);
            acc.push(index);
        } else {
            acc.push(key);
        }

        return acc;
    }, []);

    const result = allKeys.reduce((acc, cur) => {
        return acc === null || acc === undefined ? acc : acc[cur];
    }, obj);

    return result !== undefined && result !== null ? result : defaultValue;
};

export const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const splitPathname = (pathname) => {
    if (typeof pathname !== 'string') {
        console.log('Path must be a string');
        return;
    }
    let path = `/${pathname}/`;
    path = path.split('/');
    path = path.filter((item) => item !== '');
    return path;
};

export const getLinkQueryFromPath = (pathname) => {
    const pathArray = pathname
        .split('/')
        .filter((i) => i.length)
        .reverse();

    return {
        slug: pathArray[0] || '',
        basePath: pathArray[1] || '',
        subparent: pathArray[2] || '',
        parent: pathArray[3] || '',
    };
};

export const getPageLinkProps = (pathname, template = '/page') => {
    if (!pathname) {
        return {};
    }
    return {
        as: pathname,
        href: {
            pathname: template,
            query: getLinkQueryFromPath(pathname),
        },
    };
};

export const getSlug = (str) => {
    const pathname = splitPathname(str);
    return pathname[pathname.length - 1];
};

export const getBlogAsPath = (post) => {
    if (!post.link) return post.slug;
    const postUrl = url.parse(post.link);
    return postUrl.pathname ? postUrl.pathname : post.slug;
};

export const isBlogPost = (path) => {
    return path.includes('blog');
};

export const isValidEmail = (input) => {
    if (typeof input !== 'string') {
        console.warn('Email input must be a string.');
        return false;
    }
    return /.+@.+\..+/.test(input);
};

export const getElemOffset = (el) => {
    if (typeof window === 'undefined') {
        console.log('window object not available');
        return;
    }
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
};

export const stripHtmlTags = (html) => {
    return html ? html.replace(/<[^>]+>/g, '') : '';
};

export const getHeadings = (html) => {
    if (!html) return [];
    // grab all the heading from the content
    const expression = /<h2.*>(.*?)<\/h2>/g;
    const matches = html.match(expression) || [];
    const headings = matches.map((item) => {
        const startString = item.indexOf('>');
        const endString = item.indexOf('</');
        const title = item.substring(startString + 1, endString);
        if (title === '') {
            return;
        } else {
            return title;
        }
    });

    return headings;
};

export const splitByWord = (str) => {
    if (typeof str !== 'string') {
        console.log('Must pass a string to kebabCase');
        return [];
    }

    /**
     * URI with spaces
     */
    if (str.match('%20')) {
        return str.split('%20');
    }

    /**
     * Spaces
     */
    if (str.match(' ')) {
        return str.split(' ');
    }

    /**
     * Dashes
     */
    if (str.match('-')) {
        return str.split('-');
    }

    /**
     * Underscores
     */
    if (str.match('_')) {
        return str.split('_');
    }

    /**
     * Camel case
     */
    if (/[A-Z]/.test(str)) {
        const splitBySpaces = str.split('').reduce((acc, letter, i) => {
            if (/[A-Z]/.test(letter) && i !== 0) {
                acc += ' ';
            }

            acc += letter;

            return acc;
        }, '');

        return splitBySpaces.split(' ');
    }

    return [str];
};

export const toCapitalCase = (str) => {
    const wordArray = splitByWord(str);

    const capitalized = wordArray.map((word) => {
        const firstLetter = word.slice(0, 1);
        const uppercase = firstLetter.toUpperCase();
        return uppercase + word.slice(1);
    });

    return capitalized.join(' ');
};

export function alphabetize(prop) {
    return (a, b) => {
        const aValue = prop?.['a'] ?? a;
        const bValue = prop?.['b'] ?? b;
        if (aValue.toLowerCase() < bValue.toLowerCase()) {
            return -1;
        } else if (aValue.toLowerCase() > bValue.toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
    };
}

export function alphabetizeArrayByKey(array, key) {
    if (!array || !Array.isArray(array)) return [];
    const newArray = array.sort(function (a, b) {
        const varA = a?.[key];
        const varB = b?.[key];

        if (varA < varB) {
            return -1;
        }

        if (varA > varB) {
            return 1;
        }

        return 0;
    });
    return newArray;
}

const tagsToReplace = {
    '&#8211;': '-',
    '&#038;': '&',
    '&#8217;': "'",
};

/**
 * Escaping HTML entities because dangerouslySetInnerHTML
 * doesn't work with <title> tags
 * https://github.com/zeit/next.js/issues/910
 */
export function replaceHtmlEntities(str) {
    if (!str || typeof str !== 'string') return;
    return str.replace(/&\S+;/g, (tag) => {
        return tagsToReplace[tag] || str;
    });
}

export const appendScript = (src) => {
    if (typeof src !== 'string') {
        console.warn('You must provide a string as a source');
        return;
    }
    if (typeof document === 'undefined') {
        console.warn('No document found');
        return;
    }
    const script = document.createElement('script');
    script.src = src;
    document.body.appendChild(script);
};

export const getStatusCode = (props) => {
    const status =
        props?.['statusCode'] ||
        props?.['data']?.['statusCode'] ||
        props?.['data']?.['data']?.['statusCode'];
    return status;
};

export const shouldShowError = (props) => {
    const {statusCode = 200, error, data = []} = props;
    // 1. response is empty array
    // http://localhost:3000/wordpress-api/pages?filter[pagename]=/test&_embed&limit=1
    if (Array.isArray(data) && !data.length) return true;

    // 2. wordpress send back an object with a 404 status if the route isn't found
    // http://localhost:3000/wordpress-api/nothere
    const wordpressStatusCode = props?.['data']?.['data']?.['status'];
    if (error || wordpressStatusCode || statusCode !== 200) {
        return true;
    }
    // 3. After going through postCreator function data object will be an object with default values
    // check if that default value has a data key
    if ((!data?.id && !data?.['0']?.['id']) || Object.keys(data).length === 0) {
        return true;
    }
    return false;
};

export const triggerBoldChat = () => {
    const chat = document.querySelector('.bcFloat a');
    if (!chat) {
        console.warn('Boldchat script not found.');
        return;
    }
    if (!window.nanorep) {
        chat.click();
    } else {
        nanorep.floatingWidget.expand();
        const widget = document.querySelector('.widget-floating--state-6');
        if (!widget) {
            chat.click();
        }
    }
};

export const isSpanishPage = (slug) => {
    return slug === 'esp';
};

export const shouldShowVobForm = (path) => {
    // whitelist for page legacy template
    const verifyFormWhiteList = [
        '/payment-options',
        '/rehab-guide/free',
        '/alcohol-rehab/insurance-coverage',
        '/drug-detox/insurance',
        '/insurance-coverage',
        '/insurance-coverage/suboxone',
        '/insurance-coverage/humana',
        '/insurance-coverage/aetna',
        '/insurance-coverage/amerigroup',
        '/insurance-coverage/anthem',
        '/insurance-coverage/bulimia',
        '/insurance-coverage/outpatient',
        '/insurance-coverage/drug-counseling',
        '/insurance-coverage/heroin-cocaine-and-meth',
        '/insurance-coverage/kaiser-permanente',
        '/insurance-coverage/providence-health-plan',
        '/insurance-coverage/state-farm',
        '/insurance-coverage/other-insurance-providers',
        '/insurance-coverage/unitrin',
        '/insurance-coverage/unitedhealth-group',
        '/insurance-coverage/-cross--shield-association',
        '/intensive-outpatient-programs/cost',
        '/alcohol-rehab/cost',
        '/drug-detox/cost',
        '/alcohol-rehab/how-to-pay',
        '/insurance-coverage/using-medicaid-to-pay-for-rehab',
        '/insurance-coverage/how-to-pay-for-drug-and-alcohol-rehab-with-sierra-health-and-life-insurance',
        '/insurance-coverage/how-to-pay-for-drug-and-alcohol-rehab-with-the-veterans-choice-program',
        '/insurance-coverage/how-to-pay-for-drug-rehab-with-magellan-insurance',
        '/insurance-coverage/how-to-pay-for-drug-rehab-with-meritain-insurance',
        '/insurance-coverage/how-to-pay-for-drug-rehab-with-avmed-insurance',
        '/insurance-coverage/how-to-pay-for-drug-rehab-with-beacon-health-options',
        '/insurance-coverage/how-to-pay-for-drug-rehab-with-cigna-insurance',
        '/insurance-coverage/how-to-pay-for-drug-rehab-with-health-plan-of-nevada',
        '/insurance-coverage/how-to-pay-for-rehab-with-behavioral-healthcare-options-bho',
    ];
    const verifyFormWhiteListTrailing = [...verifyFormWhiteList].map((path) => {
        return `${path}/`;
    });
    const pathname = url.parse(path, true).pathname;
    const showVerificationForm =
        verifyFormWhiteList.includes(pathname) ||
        verifyFormWhiteListTrailing.includes(pathname);
    return showVerificationForm;
};

/**
 * @param {string} path
 * Check whether to show CTA Sticky Footer against a blacklist of pages.
 */
/**
 * @param {string} path
 * Check whether to show CTA Sticky Footer against a blacklist of pages.
 *
 * return a config object
 */
export const shouldShowCTAStickyFooter = (path) => {
    // Blacklist for CTA sticky footer
    const verifyFromBlacklist = []; // do not show sticky footer.
    const verifyFromBlacklistTrailing = [...verifyFromBlacklist].map((path) => {
        return `${path}/`;
    });

    const verifyFromCustomList = ['/verify-insurance']; // only show calltrail phone number button on sticky footer.
    const verifyFromCustomListTrailing = [...verifyFromCustomList].map((path) => {
        return `${path}/`;
    });

    const pathname = url.parse(path, true).pathname;

    let CTAStickyFooterConfig = {
        showStickyFooter: true,
        showActionButton: true,
    };

    const hideCTAStickyFooter =
        verifyFromBlacklist.some((p) => pathname.includes(p)) ||
        verifyFromBlacklistTrailing.some((p) => pathname.includes(p));

    const showCTAStickyFooterPartialy =
        verifyFromCustomList.some((p) => pathname.includes(p)) ||
        verifyFromCustomListTrailing.some((p) => pathname.includes(p));

    if (hideCTAStickyFooter) {
        CTAStickyFooterConfig['showStickyFooter'] = false;
    } else if (showCTAStickyFooterPartialy) {
        CTAStickyFooterConfig['showActionButton'] = false;
    }

    return CTAStickyFooterConfig;
};

// https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
export const getCookie = (name) => {
    let v = decodeURIComponent(document.cookie).match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : '';
};

export const formatPhoneNumber = (value) => {
    if (!value || typeof value !== 'string') {
        console.warn('empty value');
        return '';
    }

    let num = value.replace(/\D/g, '');

    if (num.length > 4) {
        var dash1 = '(';
        var dash2 = ') ';
    } else {
        var dash1 = '';
        var dash2 = '';
    }

    if (num.length > 7) {
        var dash3 = '-';
    } else {
        var dash3 = '';
    }

    value =
        dash1 +
        num.substring(0, 3) +
        dash2 +
        num.substring(3, 6) +
        dash3 +
        num.substring(6, 10);

    return value;
};

export const validateEmail = (value) => {
    const isValid = value.indexOf('@') >= 1 && value.indexOf('.') >= 1;
    return isValid;
};

export const getTimeZone = () => {
    function timezone(dt) {
        return /\((.*)\)/.exec(new Date().toString())[1];
    }

    const dt = new Date();
    const timeZone = timezone(dt);

    return timeZone;
};

export const filterPaths = (paths) => {
    const filteredPaths = paths.reduce((acc, path) => {
        const firstCharacter = path.substring(0, 1);
        if (firstCharacter !== '?' && path.includes('?')) {
            acc.push(path.split('?')[0]);
        } else if (firstCharacter !== '#' && firstCharacter !== '?' && path !== 'page') {
            acc.push(path);
        }
        return acc;
    }, []);
    return filteredPaths;
};

export const formatDate = (value) => {
    // replace all characters other than numbers

    let num = value.replace(/\D/g, '');
    const firstNumber = parseInt(num[0]);
    if (firstNumber > 1) {
        return '';
    }

    let dash1;
    let dash2;

    if (value.length > 2) {
        dash1 = '/';
    } else {
        dash1 = '';
    }
    if (value.length > 5) {
        dash2 = '/';
    } else {
        dash2 = '';
    }

    value =
        num.substring(0, 2) + dash1 + num.substring(2, 4) + dash2 + num.substring(4, 8);

    return value;
};

// https://github.com/verlok/vanilla-lazyload#using-an-async-script--getting-the-instance-reference
export const setupLazyLoadOptions = `
    window.lazyLoadOptions = {
        elements_selector: '[loading=lazyel], [loading=lazybg]',
    };
    window.addEventListener(
        "LazyLoad::Initialized",
        function (event) {
            window.lazyLoadInstance = event.detail.instance;
        },
        false
    );
`;

export const isValidJson = (schema) => {
    let isValidJson;
    try {
        schema = JSON.parse(schema);
        isValidJson = true;
    } catch (e) {
        return null;
    }
    return isValidJson;
};

export const removeTrailingSlash = (url) => {
    const lastChar = url.substr(-1);
    if (lastChar === '/') {
        url = url.substring(0, url.length - 1);
    }
    return url;
};

export const extractSlug = (url) => {
    if (!url) return '/';

    let trimmed = url.substring(
        0,
        url[url.length - 1] === '/' ? url.length - 1 : url.length,
    );

    let urlArr = trimmed.split('/');
    // console.log('urlArr', urlArr);

    const filtered = urlArr.filter((k) => k != '');
    // console.log('filtered', filtered);

    const slug = `/${filtered.splice(2).toString().replace(/,/, '/')}`;
    // console.log('slug', slug);

    return slug;
};

export const scrollToElement = (id, marginTop = 150) => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const element = document.getElementById(id);
        if (!element) return;
        const elOffset = getElemOffset(element);

        elOffset.top &&
            window.scrollTo({
                top: elOffset.top - marginTop,
                behavior: 'smooth',
            });
    }
};

/* Content segmentation */
export const setContentSegment = (contentSegment) => {
    if (contentSegment) {
        window.ga && window.ga('set', 'contentGroup1', contentSegment);
        document.body.setAttribute('data-segment', contentSegment);

        document.body.classList = [];
        document.body.classList.add(contentSegment);
        window.__contentSegment = contentSegment;
    }
};

export function parseYoastHead(yoast_head) {
    let yoastHead = yoast_head;
    if (yoastHead) {
        yoastHead = yoastHead.replace(/\/admin\./gi, '/');
        yoastHead = yoastHead.replace(/\/stagingadmin\./gi, '/');
        yoastHead = yoastHead.replace(/\/local\./gi, '/');
        yoastHead = parse(yoastHead);
    }

    return yoastHead;
}

/**
 * Updates rehabs facilities api yoast_data with detox references.
 * @param {*} yoast_head
 * @returns
 */
export function updateYoastHost(yoast_head, slug) {
    let yoastHead = yoast_head;

    if (yoastHead) {
        yoastHead = yoastHead
            .replace(/rehabs.com/gi, 'withdrawal.net')
            .replace(/rehab/gi, 'withdrawal')
            .replace(/listings/gi, 'providers');
    }

    return yoastHead;
}

export const trimTrailingSlash = (url) => {
    if (!url || typeof url !== 'string') return;
    return url.substring(0, url.length - 1);
};

/* Exclude features on compliance pages */
export const isCompliancePage = (path) => {
    const pageURL = trimTrailingSlash(path);

    if (!pageURL) return;

    const complianceUrlsTrailingSlash = [...complianceUrls].map((pageURL) => {
        return `${pageURL}/`;
    });
    const complianceUrlsBeginningSlash = [...complianceUrls].map((pageURL) => {
        return `/${pageURL}`;
    });
    const pathname = url.parse(pageURL, true).pathname;
    const isCompliancePage =
        complianceUrls.includes(pathname) ||
        complianceUrlsTrailingSlash.includes(pathname) ||
        complianceUrlsBeginningSlash.includes(pathname);
    return isCompliancePage;
};

export const getSegmentName = () => {
    if (typeof window === 'undefined') {
        console.log('window object not available');
        return;
    }
    const el = document.getElementById('page-segment');
    const segment = (el && el.dataset.segment) || '';
    return segment;
};

export const getSegmentNumber = (segment) => {
    const segmentName = segment || getSegmentName();
    let segmentNumber = 0;
    switch (segmentName) {
        case 'info-pr':
            segmentNumber = 1;
            break;
        case 'info-drug-illegal':
            segmentNumber = 2;
            break;
        case 'info-drug-otc':
            segmentNumber = 2;
            break;
        case 'info-alcohol':
            segmentNumber = 2;
            break;
        case 'info-addiction':
            segmentNumber = 2;
            break;
        case 'behavioral-addiction':
            segmentNumber = 2;
            break;
        case 'other':
            segmentNumber = 2;
            break;
        case 'info-treatment':
            segmentNumber = 3;
            break;
        case 'info-aftercare':
            segmentNumber = 3;
            break;
        case 'info-treatment-drugs':
            segmentNumber = 3;
            break;
        case 'search-result-page':
            segmentNumber = 3;
            break;
        case 'facility-pages':
            segmentNumber = 4;
        case 'local-pages':
            segmentNumber = 4;
            break;
        case 'insurance-pages':
            segmentNumber = 4;
            break;
        case 'conversion-pages':
            segmentNumber = 5;
            break;
        default:
            segmentNumber = 0;
            break;
    }
    return segmentNumber;
};

export const hideMobileTopMenu = (asPath) => {
    const hideTopMenu =
        asPath === '/about/' ||
        asPath === '/verify-insurance/' ||
        asPath === '/admissions/team/text-support/' ||
        asPath.includes('/providers');

    return hideTopMenu;
};

export const formatPostsData = (data) => {
    return (Array.isArray(data) && data.map((post) => pageCreator(post))) || [];
};
export const getPathname = (link) => {
    const postUrl = url.parse(link);
    return postUrl.pathname ? postUrl.pathname : '/';
};
/**
 * Updates rehabs facilities api yoast_data with detox references.
 * @param {*} yoast_head
 * @returns
 */
export function switchYoastToHost(yoast_head, slug) {
    let yoastHead = yoast_head;

    if (yoastHead) {
        yoastHead = yoastHead
            .replace(/rehabs.com/gi, 'withdrawal.net')
            .replace(/rehab/gi, 'withdrawal')
            .replace(/listings/gi, 'providers')
            .replace(/aac-facilities/gi, '/centers');

        if (slug) {
            const hostMainFacilityCanonicalList = {
                'greenhouse-treatment-center': {
                    actual: 'greenhouse-treatment-center-1868084797',
                    expected: 'greenhouse-treatment-center-1868138682',
                },
                'laguna-treatment-center': {
                    actual: 'laguna-treatment-center-333087879',
                    expected: 'laguna-treatment-center-1059405758',
                },
                'oxford-treatment-center': {
                    actual: 'oxford-treatment-center---oxford-4213224323',
                    expected: 'oxford-treatment-center---oxford-4213337640',
                },
                'desert-hope-treatment-center': {
                    actual: 'desert-hope-treatment-center-3435611194',
                    expected: 'desert-hope-treatment-center-3435711353',
                },
                'river-oaks-treatment-center': {
                    actual: 'river-oaks-treatment-center-1250126437',
                    expected: 'river-oaks-treatment-center-1250239574',
                },
                'recovery-first-treatment-center': {
                    actual: 'recovery-first-treatment-center,-hollywood-312626349',
                    expected: 'recovery-first-treatment-center,-hollywood-312680032',
                },
                'adcare-treatment-centers': {
                    actual: 'adcare-hospital-2084111403',
                    expected: 'adcare-hospital-2084165452',
                },
            };

            yoastHead = yoastHead.replace(
                new RegExp(hostMainFacilityCanonicalList?.[slug]?.actual, 'gi'),
                hostMainFacilityCanonicalList?.[slug]?.expected,
            );
        }
    }

    return yoastHead;
}

export const getSwapNumber = (segment, event) => {
    const highIntentCallTrackingNumber = {
        href: 'tel:+1-662-281-1326',
        display: '662-281-1326',
    };

    const midIntentCallTrackingNumber = {
        href: 'tel:+1-703-672-8276',
        display: '703-672-8276',
    };

    const lowIntentCallTrackingNumber = {
        href: 'tel:+1-888-935-1318',
        display: '888-935-1318',
    };

    // Segments
    const highConvertingSegments = [
        'info-treatment',
        'facility-pages',
        'insurance-pages',
        'listing-pages',
        'local-pages',
        'conversion-pages',
        'directory-result-state',
    ];
    const isHighConvertingSegment = highConvertingSegments?.includes(segment);

    if (isHighConvertingSegment) return highIntentCallTrackingNumber;

    if (getSegmentNumber(segment) <= 3) {
        // Attempt to grab value from event param first
        let liftAICookie = event?.detail?.value;
        if (!liftAICookie) {
            // on serverside load event will be undefined, get value from cookie instead
            liftAICookie = typeof window !== 'undefined' ? getCookie('vs_conv_ai') : '';
        }
        if (Number(liftAICookie.split('-')?.[0]) >= 60)
            return midIntentCallTrackingNumber;
    }

    return lowIntentCallTrackingNumber;
};

export const getSegmentFromProps = (pageProps) => {
    return (
        pageProps?.contentSegment ||
        pageProps?.data?.content_segment ||
        pageProps?.data?.[0]?.content_segment ||
        ''
    );
};

export const loadStateFromLocalStorage = (state = {}) => {
    try {
        const serializedState = localStorage.getItem('appState');
        if (serializedState === null) {
            return state;
        }
        return {...state, ...JSON.parse(serializedState)};
    } catch (e) {
        console.warn(e);
        return state;
    }
};

export const saveStateToLocalStorage = (state = {}) => {
    // only save history to localStorage
    const {history = []} = state;
    try {
        const serializedState = JSON.stringify({history});
        localStorage.setItem('appState', serializedState);
    } catch (e) {
        /* log error */
        console.warn(e);
    }
};

export const getSessionStorageState = (state = {}) => {
    try {
        const serializedState = sessionStorage.getItem('AacApp');

        if (serializedState === null) {
            return state;
        }
        return {...state, ...JSON.parse(serializedState)};
    } catch (e) {
        console.warn(e);
        return state;
    }
};
