// TODO: Remove hardcoded domain
export const domainUrl = 'https://withdrawal.net';

/**
 * Phone number registered with CallRail
 * Number should swap on DOM load
 */

export const callTrackingNumber = '(888) 935-1318';
export const callTrackingNumberLink = 'tel:+1-888-935-1318';

// Verify Form Tracking Number
export const SsvobVerificationPhone = '(888) 935-1318';
export const SsvobVerificationPhoneLink = 'tel:+1-888-935-1318';

//form error message
export const formMessage = {
    error: 'There was an issue with your request. Please contact us by phone or try back later',
    success:
        'We have recieved your message. We will be in touch within the next 48 hours',
};

//recaptcha errors
export const recaptchaErrors = {
    isRobotError: '*reCAPTCHA has identified you as a robot',
    uncheckedError: '*Please check the reCAPTCHA box',
};

export const RECAPTCHA_V3_KEY = '6LdjX88ZAAAAAPYKh7VFJ00CM-0OlAaYIvTa6BcW';

// short names for treatmetn centers
export const treatmentCenterShortNames = [
    'Desert Hope',
    'Greenhouse',
    'Laguna',
    'Oxford',
    'Recovery First',
    'River Oaks',
];

/**
 * VTX provider names/ids that are specifically in network with aac facilites
 */
export const inNetworkProviders = [
    {payer_id: 'VTX0518', payer_name: '1199 National Benefit Fund (SEUI)'},
    {payer_id: 'VTX0490', payer_name: 'AETNA'},
    {payer_id: 'VTX0498', payer_name: 'BC OF IDAHO'},
    {payer_id: 'VTX0453', payer_name: 'BLUE CROSS BLUE SHIELD'},
    {payer_id: 'VTX0085', payer_name: 'BLUE SHIELD'},
    {payer_id: 'VTX0532', payer_name: 'BLUE CROSS AND BLUE SHIELD OF TEXAS'},
    {payer_id: 'VTX0127', payer_name: 'CIGNA'},
    {payer_id: 'VTX0523', payer_name: 'MAGELLAN HEALTH SERVICES'},
    {payer_id: 'VTX0322', payer_name: 'QUALCARE'},
    {payer_id: 'VTX0154', payer_name: 'ROCKY MOUNTAIN HMO COLORADO'},
    {payer_id: 'VTX0027', payer_name: 'SCHALLER ANDERSON AETNA BETTER HEALTH OF NY'},
    {payer_id: 'VTX0265', payer_name: 'SCHALLER ANDERSON AETNA BETTER HEALTH OF OHIO'},
    {payer_id: 'VTX0130', payer_name: 'TUFTS HEALTH PLAN'},
    {payer_id: 'VTX0063', payer_name: 'UNITEDHEALTHCARE'},
    {payer_id: 'VTX0338', payer_name: 'HUMANA'},
    {payer_id: 'VTX0266', payer_name: 'HEALTH FIRST HEALTH PLAN'},
];
/**
 * Insurance code types from vtx api response
 */
export const approvedInsuranceTypes = [
    {code: 'HM', label: 'Health Maintenance Organization (HMO)'},
    {code: 'PR', label: 'Preferred Provider Organization (PPO)'},
];

// US States Array
export const usStates = [
    {name: 'Alabama', abv: 'AL'},
    {name: 'Alaska', abv: 'AK'},
    {name: 'Arizona', abv: 'AZ'},
    {name: 'Arkansas', abv: 'AR'},
    {name: 'California', abv: 'CA'},
    {name: 'Colorado', abv: 'CO'},
    {name: 'Connecticut', abv: 'CT'},
    {name: 'Delaware', abv: 'DE'},
    {name: 'District of Columbia', abv: 'DC'},
    {name: 'Florida', abv: 'FL'},
    {name: 'Georgia', abv: 'GA'},
    {name: 'Hawaii', abv: 'HI'},
    {name: 'Idaho', abv: 'ID'},
    {name: 'Illinois', abv: 'IL'},
    {name: 'Indiana', abv: 'IN'},
    {name: 'Iowa', abv: 'IA'},
    {name: 'Kansas', abv: 'KS'},
    {name: 'Kentucky', abv: 'KY'},
    {name: 'Louisiana', abv: 'LA'},
    {name: 'Maine', abv: 'ME'},
    {name: 'Maryland', abv: 'MD'},
    {name: 'Massachusetts', abv: 'MA'},
    {name: 'Michigan', abv: 'MI'},
    {name: 'Minnesota', abv: 'MN'},
    {name: 'Mississippi', abv: 'MS'},
    {name: 'Missouri', abv: 'MO'},
    {name: 'Montana', abv: 'MT'},
    {name: 'Nebraska', abv: 'NE'},
    {name: 'Nevada', abv: 'NV'},
    {name: 'New Hampshire', abv: 'NH'},
    {name: 'New Jersey', abv: 'NJ'},
    {name: 'New Mexico', abv: 'NM'},
    {name: 'New York', abv: 'NY'},
    {name: 'North Carolina', abv: 'NC'},
    {name: 'North Dakota', abv: 'ND'},
    {name: 'Ohio', abv: 'OH'},
    {name: 'Oklahoma', abv: 'OK'},
    {name: 'Oregon', abv: 'OR'},
    {name: 'Pennsylvania', abv: 'PA'},
    {name: 'Puerto Rico', abv: 'PR'},
    {name: 'Rhode Island', abv: 'RI'},
    {name: 'South Carolina', abv: 'SC'},
    {name: 'South Dakota', abv: 'SD'},
    {name: 'Tennessee', abv: 'TN'},
    {name: 'Texas', abv: 'TX'},
    {name: 'Utah', abv: 'UT'},
    {name: 'Vermont', abv: 'VT'},
    {name: 'Virginia', abv: 'VA'},
    {name: 'Washington', abv: 'WA'},
    {name: 'West Virginia', abv: 'WV'},
    {name: 'Wisconsin', abv: 'WI'},
    {name: 'Wyoming', abv: 'WY'},
];

// Compliance URLs
export const complianceUrls = [
    'privacy-policy',
    'terms-of-use',
    'verify-insurance',
    'admissions/process',
    'shortcodes',
];

export const aacListingsData = [
    {
        facility_id: '113346',
        name: 'Laguna Treatment Center',
        listingUrl: '/listings/laguna-treatment-center-333087879/',
        facilityUrl: '/aac-facilities/laguna-treatment-center/',
        imagePath: '/static/aac-facilities-images/laguna.jpg',
        iconPath: '/static/aac-facilities-logo/laguna-icon.png',
    },
    {
        facility_id: '100146',
        name: 'Oxford Treatment Center',
        listingUrl: '/listings/oxford-treatment-center---etta-3799493150/',
        facilityUrl: '/aac-facilities/oxford-treatment-center/',
        imagePath: '/static/aac-facilities-images/oxford.jpg',
        iconPath: '/static/aac-facilities-logo/oxford-icon.png',
    },
    {
        facility_id: '53885',
        name: 'Greenhouse Treatment Center',
        listingUrl: '/listings/greenhouse-treatment-center-1868084797/',
        facilityUrl: '/aac-facilities/greenhouse-treatment-center/',
        imagePath: '/static/aac-facilities-images/greenhouse.jpg',
        iconPath: '/static/aac-facilities-logo/greenhouse-icon.png',
    },
    {
        facility_id: '100159',
        name: 'Desert Hope Treatment Center',
        listingUrl: '/listings/desert-hope-treatment-center-3435611194/',
        facilityUrl: '/aac-facilities/desert-hope-treatment-center/',
        imagePath: '/static/aac-facilities-images/desert-hope.jpg',
        iconPath: '/static/aac-facilities-logo/desert-hope-icon.png',
    },
    {
        facility_id: '53683',
        name: 'Recovery First Treatment Center',
        listingUrl: '/listings/recovery-first-treatment-center,-hollywood-312626349/',
        facilityUrl: '/aac-facilities/recovery-first-treatment-center/',
        imagePath: '/static/aac-facilities-images/recovery.jpg',
        iconPath: '/static/aac-facilities-logo/recovery-first-icon.png',
    },
    {
        facility_id: '113137',
        name: 'River Oaks Treatment Center',
        listingUrl: '/listings/river-oaks-treatment-center-1250126437/',
        facilityUrl: '/aac-facilities/river-oaks-treatment-center/',
        imagePath: '/static/aac-facilities-images/river-oaks.jpg',
        iconPath: '/static/aac-facilities-logo/river-oaks-icon.png',
    },
    {
        facility_id: '54049',
        name: 'AdCare Massachusetts',
        listingUrl: '/listings/adcare-hospital-2084111403/',
        facilityUrl: '/aac-facilities/adcare-treatment-centers/',
        imagePath:
            'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/website-logos/adcare/adcare-logo-title.png',
        iconPath:
            'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/website-logos/adcare/adcare-logo-title.png',
    },
    {
        facility_id: '112781',
        name: 'AdCare Rhode Island',
        listingUrl: '/listings/adcare-rhode-island-1108349908/',
        facilityUrl: '/aac-facilities/adcare-treatment-centers-rhode-island/',
        imagePath: '/static/aac-facilities-images/adcare-rhode-island.jpg',
        iconPath:
            'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/website-logos/adcare/adcare-logo-title.png',
    },
];
