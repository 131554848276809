const menuItemCreator = ({
    ID = 0,
    menu_order = 0, // allows sorting of menus
    menu_item_parent = '0', // points to the ID of the menu (not the post id)
    title = '', // menu title, != post title
    url = '', // absolute urls unless type === custom
    slug = '',
    target = '', // Eg. "" | "_blank"
} = {}) => ({
    ID,
    menu_order,
    menu_item_parent,
    title,
    url,
    slug,
    target,
});

export default menuItemCreator;
